/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { CombinedState, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DOCUMENT_PER_PAGE } from 'common/constants';
import { getWorkflowsInStage } from 'common/utils/currentStage';
import {
  getAllJobs,
  getJobById,
  getJobDataById,
  getJobFilesById,
  getJobStatusById,
  updateDeepDocJobStatus
} from 'lib/apis/job';
import type {
  DeepDocJobWorkflowStatus,
  Job,
  JobList,
  File,
  DeepDocJob,
  JobFilterInput,
  JobStatus
} from 'lib/graphql/__generated__/graphql';

import type { RootState } from '..';

export type IFile = File & { selected?: boolean; progress?: number };
export type IJobFIle = Array<IFile>;

interface IInitialState {
  job?: Job;
  jobs?: {
    data?: JobList;
    loading: boolean;
    error?: string;
  };
  files?: IJobFIle;
  loading: boolean;
  error?: string;
}

function createInitialState(): IInitialState {
  return {
    job: undefined,
    jobs: undefined,
    files: undefined,
    loading: true
  };
}

function createExtraActions() {
  return {
    fetchJobData: createAsyncThunk<unknown, { id: string; page: number }, { state: RootState }>(
      'job/fetchJobData',
      async ({ id, page }, { rejectWithValue }) => {
        try {
          const job = await getJobById(id, DOCUMENT_PER_PAGE, page);
          return job.data.job;
        } catch (err: unknown) {
          return rejectWithValue(err);
        }
      }
    ),
    pullJobData: createAsyncThunk<unknown, { id: string }, { state: RootState }>(
      'job/pullJobData',
      async ({ id }, { rejectWithValue }) => {
        try {
          const job = await getJobDataById(id);
          return job.data.job;
        } catch (err: unknown) {
          return rejectWithValue(err);
        }
      }
    ),
    fetchJobFileData: createAsyncThunk<unknown, { id: string }, { state: RootState }>(
      'job/fetchJobFileData',
      async ({ id }, { rejectWithValue }) => {
        try {
          const job = await getJobFilesById(id);
          return job.data.job;
        } catch (err: unknown) {
          return rejectWithValue(err);
        }
      }
    ),
    fetchJobStatus: createAsyncThunk<unknown, { id: string; page: number }, { state: RootState }>(
      'job/fetchJobStatus',
      async ({ id, page }, { rejectWithValue }) => {
        try {
          const job = await getJobStatusById(id, DOCUMENT_PER_PAGE, page);
          return job.data.job;
        } catch (err: unknown) {
          return rejectWithValue(err);
        }
      }
    ),
    fetchAllJob: createAsyncThunk<
      unknown,
      {
        limit: number;
        page: number;
        statuses?: JobStatus[];
        services?: string[];
        phases?: string[];
        pageRange?: { from: string; to: string };
        eta?: string;
        reviewers?: string[];
        companies?: string[];
        title?: string;
        uploadedAt?: {
          from: string;
          to: string;
        };
        orderBy?: {
          asc: boolean;
          field: string;
        };
      },
      { state: RootState }
    >(
      'job/fetchAllJob',
      async (
        {
          limit,
          page,
          statuses = [],
          services = [],
          phases = [],
          pageRange = {},
          eta,
          reviewers = [],
          companies = [],
          title = '',
          uploadedAt,
          orderBy
        },
        { rejectWithValue }
      ) => {
        try {
          const filter: JobFilterInput = {};
          if (statuses.length > 0) {
            filter.status = statuses
          }
          if (services.length > 0) filter.serviceType = services.map((serv) => serv.toUpperCase());
          if (phases.length > 0) {
            filter.workflowStatus = phases.reduce<DeepDocJobWorkflowStatus[]>((acc, phase) => {
              const workflow = getWorkflowsInStage(phase);
              return [...acc, ...(workflow ?? [])];
            }, filter.workflowStatus ?? []);
          }
          if (pageRange.from && pageRange.to) {
            filter.pageRange = { from: parseInt(pageRange.from), to: parseInt(pageRange.to) };
          }
          if (eta) {
            filter.eta = parseInt(eta);
          }
          if (title) {
            filter.title = title;
          }
          if (reviewers.length > 0) filter.reviewerIds = reviewers;
          if (companies.length > 0) filter.organizationId = companies;
          if (uploadedAt?.from && uploadedAt.to) {
            filter.uploadedAt = uploadedAt;
          }
          if (orderBy) {
            filter.orderBy = orderBy;
          }

          if (!orderBy) {
            filter.orderBy = {
              asc: false,
              field: 'inserted_at'
            };
          }

          const job = await getAllJobs({ limit, page, filter });
          return { jobs: job.data.jobs };
        } catch (err: unknown) {
          return rejectWithValue(err);
        }
      }
    ),
    updateJobStatus: createAsyncThunk<
      unknown,
      { documentId: string; status: DeepDocJobWorkflowStatus },
      { state: RootState }
    >('job/updateJobStatus', async ({ documentId, status }, { rejectWithValue }) => {
      try {
        await updateDeepDocJobStatus(documentId, status).then(({ data }) => data);
        return { status };
      } catch (err: unknown) {
        return rejectWithValue(err);
      }
    })
  };
}

const extraActions = createExtraActions();

const createExtraReducers = (builder: any) => {
  const { fulfilled: pullJobDataFulfilled } = extraActions.pullJobData;
  const { fulfilled: fetchJobFileData } = extraActions.fetchJobFileData;

  const {
    pending: jobDataPending,
    fulfilled: jobDataFulfilled,
    rejected: jobDataRejected
  } = extraActions.fetchJobData;

  const {
    pending: fetchAllJobPending,
    fulfilled: fetchAllJobFulfilled,
    rejected: fetchAllJobRejected
  } = extraActions.fetchAllJob;

  const { fulfilled: updateJobStatusFulFilled, rejected: updateJobStatusRejected } =
    extraActions.updateJobStatus;

  const { fulfilled: fetchJobStatusFulFilled } = extraActions.fetchJobStatus;

  /**
   * GET JOB DATA
   */
  builder.addCase(jobDataPending, (state: CombinedState<IInitialState>) => {
    state.loading = true;
  });
  builder.addCase(
    jobDataFulfilled,
    (state: CombinedState<IInitialState>, action: PayloadAction<Job>) => {
      state.job = { ...state.job, ...action.payload };
      state.files = [...(action.payload.files?.data ?? [])];
      state.loading = false;
    }
  );
  builder.addCase(jobDataRejected, (state: CombinedState<IInitialState>, action: any) => {
    state.error = action.error;
    state.loading = false;
  });

  /**
   * PULL MINIMUM JOB DATA
   */
  builder.addCase(
    pullJobDataFulfilled,
    (state: CombinedState<IInitialState>, action: PayloadAction<Job>) => {
      state.job = { ...state.job, ...action.payload };
    }
  );

  /**
   * PULL JOB FILES DATA
   */
  builder.addCase(
    fetchJobFileData,
    (state: CombinedState<IInitialState>, action: PayloadAction<Job>) => {
      state.job = { ...state.job, ...action.payload };
    }
  );

  /**
   * GET JOB STATUS
   */
  builder.addCase(
    fetchJobStatusFulFilled,
    (state: CombinedState<IInitialState>, action: PayloadAction<DeepDocJob>) => {
      if (state.job?.id === action.payload.id) {
        (state.job as DeepDocJob).workflowStatus = action.payload.workflowStatus;
      }
    }
  );

  /**
   * GET ALL JOBS
   */
  builder.addCase(fetchAllJobPending, (state: CombinedState<IInitialState>) => {
    state.jobs = { ...state.jobs, loading: true };
  });
  builder.addCase(
    fetchAllJobFulfilled,
    (state: CombinedState<IInitialState>, action: PayloadAction<{ jobs: JobList }>) => {
      state.jobs = { ...state.jobs, data: action.payload.jobs, loading: false };
    }
  );
  builder.addCase(fetchAllJobRejected, (state: CombinedState<IInitialState>, action: any) => {
    state.jobs = { ...state.jobs, loading: false, error: action.error };
  });

  /**
   * JOB UPDATE
   */
  //  TODO: handle this
  builder.addCase(updateJobStatusFulFilled, () =>
    // state: CombinedState<IInitialState>,
    // action: PayloadAction<{ status: DeepDocJobWorkflowStatus }>
    {
      // state.status = action.payload.status;
    }
  );

  builder.addCase(updateJobStatusRejected, (state: CombinedState<IInitialState>, action: any) => {
    state.error = action.error;
  });
};

const slice = createSlice({
  name: 'job',
  initialState: createInitialState(),
  extraReducers: createExtraReducers,
  reducers: {
    setJobData(state, action: PayloadAction<Job>) {
      state.job = { ...state.job, ...action.payload };
    },
    resetJobFiles(state) {
      if (state.job?.files) {
        state.job.files.data = [];
      }
    },
    selectDocument(state, action: PayloadAction<{ documentId: string; value: boolean }>) {
      const { documentId, value } = action.payload;
      state.files = [
        ...(state.files?.map((file) => {
          if (file.id === documentId) {
            file.selected = value;
          }
          return file;
        }) ?? [])
      ];
    },
    selectAllDocuments(state, action: PayloadAction<{ value: boolean }>) {
      const { value } = action.payload;
      state.files = [
        ...(state.files?.map((file) => {
          file.selected = value;
          return file;
        }) ?? [])
      ];
    }
  }
});

// exports
export const jobActions = { ...slice.actions, ...extraActions };
export const jobReducer = slice.reducer;
