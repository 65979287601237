/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */

import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import useSystemAuth from 'common/hooks/useSystemAuth';
import type { RootState } from 'common/store';
import PageLoader from 'features/ui/PageLoader/PageLoader';
import { UserRole } from 'lib/graphql/__generated__/graphql';
import EmailIsNotVerifiedPage from 'pages/EmailIsNotVerified';
import NotFoundPage from 'pages/NotFound';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

export const REDIRECT_URI_KEY = 'gredirect';

interface ProtectedRouteProps {
  readonly isLoggedIn?: boolean;
  readonly allowedRole: UserRole[];
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ allowedRole }) => {
  const { isLoading, isAuthenticated, isUserEmailVerified, user } = useSystemAuth();
  const userMe = useSelector((state: RootState) => state.user.user);

  if (isLoading) {
    return <PageLoader />;
  }

  if (!isAuthenticated) {
    // The user is being redirected by useSystemAuth if necessary
    return null; // Optionally, you can display a loading indicator or message
  }

  if (isUserEmailVerified === false) {
    return <EmailIsNotVerifiedPage />;
  }
  if (allowedRole.includes(userMe?.role as UserRole)) {
    return <Outlet />;
  }

  if (window.location.pathname === '/' && userMe?.role === UserRole.Reviewer) {
    return <Navigate to="/jobs" />;
  }

  if (window.location.pathname === '/' && userMe?.role === UserRole.ProjectManager) {
    return <Navigate to="/dashboard" />;
  }

  if (window.location.pathname === '/' && userMe?.role === UserRole.User) {
    return <Navigate to="/services" />;
  }

  return <NotFoundPage cta={userMe?.role === UserRole.User} />;
};
