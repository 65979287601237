export const services = [
  {
    name: 'Medical records organization (US)',
    description:
      'Organize medical records by sorting chronologically, removing duplicates, building a table of contents and bookmarks, and classifying each record into one of 30+ types and optionally summarize medical records by identifying medically significant procedures, diagnoses, determinations and conclusions and summarizing them into a short written narrative.',
    eta: '24 hours',
    items: [
      {
        text: 'HIPAA compliant'
      },
      {
        text: 'Minimize file review cost'
      },
      {
        text: 'Reduce review errors'
      }
    ],
    disable: false,
    link: '/services/setup/MRO',
    reviews: ''
  },
  {
    name: 'Individual Case Study Report',
    description:
      'Analyze medical literature to determine if it contains the essential adverse event criteria for an individual case study report (ICSR) for a licenced drug',
    eta: '12 hours',
    items: [
      {
        text: 'Rapid response (< 12 hours)'
      },
      {
        text: 'Hyper scalable'
      },
      {
        text: 'Multi-lingual (> 10 languages)'
      },
      {
        text: 'HIPAA compliant'
      }
    ],
    disable: true,
    link: '/services/setup/MRO',
    reviews: '10K reviews'
  }
];

export const linksMapping: { [key: string]: string } = {
  deepdoc: '/services/setup/MRO'
};
