import type { PropsWithChildren } from 'react';
import React from 'react';

type PageHeaderContainerProps = {
  className?: string;
};

export default function PageHeaderContainer({
  children,
  className
}: PropsWithChildren<PageHeaderContainerProps>) {
  return (
    <div className={`page-header z-50 ${className ?? ''}`} data-testid="pageheader">
      {children}
    </div>
  );
}
