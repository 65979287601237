import { gql } from '@apollo/client';

export const PICK_UP_SLICE_MUTATION = gql`
  mutation pickupSlice($id: ID) {
    pickupSlice(id: $id) {
      id
      status
      deadline
      task
      meta
      user {
        id
        name
        email
      }
    }
  }
`;

export const REBUILD_DEEPDOC_PDF_MUTATION = gql`
  mutation rebuildDeepdocPdf($jobId: ID) {
    rebuildDeepdocPdf(jobId: $jobId) {
      id
    }
  }
`;

export const GENERATE_DEEPDOC_PDF_PREVIEW = gql`
  mutation generateDeepdocPdfPreview($jobId: ID) {
    generateDeepdocPdfPreview(jobId: $jobId) {
      id
    }
  }
`;

export const ASSIGN_SLICE_MUTATION = gql`
  mutation assignSlice($sliceId: ID, $userId: ID) {
    assignSlice(id: $sliceId, userId: $userId) {
      id
      jobId
      status
      deadline
      task
      meta
      user {
        id
        name
        email
      }
    }
  }
`;
