import apolloClient from 'lib/graphql/apolloClient';
import { GET_SERVICES } from 'lib/graphql/queries/service.query';

export const getAllServices = async () => {
  const { data: servicesData } = await apolloClient.query({
    query: GET_SERVICES,
    fetchPolicy: 'no-cache'
  });

  return servicesData;
};
