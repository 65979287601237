import { gql } from '@apollo/client';

export const CREATE_JOB_MUTATION = gql`
  mutation createDeepDocJob(
    $express: Boolean!
    $files: [String!]!
    $title: String!
    $taskType: DeepDocTaskType!
    $templateId: ID
    $acceptedTerms: Boolean!
  ) {
    createDeepDocJob(
      input: {
        title: $title
        files: $files
        taskType: $taskType
        express: $express
        acceptedTerms: $acceptedTerms
        templateId: $templateId
      }
    ) {
      id
      serviceType
      taskType
      title
    }
  }
`;

export const ADD_FILE_CREATE_JOB_MUTATION = gql`
  mutation AddFileAndCreateDeepDocJob(
    $express: Boolean!
    $files: [String!]!
    $acceptedTerms: Boolean!
    $deepDocJobId: ID
  ) {
    addFileAndCreateDeepDocJob(
      input: {
        express: $express
        files: $files
        acceptedTerms: $acceptedTerms
        deepDocJobId: $deepDocJobId
      }
    ) {
      id
    }
  }
`;

export const ADD_FILES_TO_JOB_MUTATION = gql`
  mutation addFileToDeepDocJob($deepDocJobId: String!, $files: [String!]!) {
    addFileToDeepDocJob(input: { deepDocJobId: $deepDocJobId, files: $files }) {
      files {
        data {
          id
        }
      }
    }
  }
`;

export const RATE_JOB_BY_ID = gql`
  mutation rateJob($id: ID!, $rating: Int!){
    rateJob(id: $id, rating: $rating) {
      id
    }
}`;