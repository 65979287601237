import { graphql } from '../__generated__/gql';

export const GET_SERVICES = graphql(`
  query OrganizationServices {
    organizationServices {
      description
      disabled
      etaAi
      etaExpert
      identifier
      name
      industry
      items {
        text
      }
    }
  }
`);
