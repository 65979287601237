/* eslint-disable @typescript-eslint/no-implicit-any-catch */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import { useEffect, useState } from 'react';

import type { User } from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';
import { getCurrentUser } from 'lib/apis/user';
import { useDispatch } from 'react-redux';

import type { AppDispatch } from '../store';
import type { IUser } from '../store/user/userSlice';
import { userActions } from '../store/user/userSlice';

export default function useSystemAuth() {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, user, isAuthenticated, getAccessTokenSilently, loginWithRedirect, error } =
    useAuth0();

  const [apiLoading, setApiLoading] = useState<boolean>(true);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState<boolean>(false);
  const [isUserEmailVerified, setIsUserEmailVerified] = useState<boolean | null>(null);

  // Attempt to get the access token silently when not authenticated
  useEffect(() => {
    const checkAuthentication = async () => {
      if (!isAuthenticated && !isLoading) {
        try {
          const results = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE
            }
          });

          console.log('Access token:', results);
        } catch (e: any) {
          if (e.error === 'login_required' || e.error === 'consent_required') {
            // Redirect to login
            loginWithRedirect({
              appState: { returnTo: window.location.pathname }
            });
          } else {
            // Handle other errors (e.g., network issues)
            console.error('Authentication error:', e);
            // Optionally, set an error state here
          }
        }
      }
    };

    checkAuthentication();
  }, [isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect]);

  // Load the user from the backend
  useEffect(() => {
    if (user) {
      retrieveAccessTokenSilently()
        .then(() => {
          setApiLoading(true);
          getCurrentUser()
            .then(({ data }) => {
              const currentUser = data.currentUser;
              dispatch(userActions.addUser({ ...user, ...currentUser } as User & IUser));
              setApiLoading(false);
              setIsUserAuthenticated(true);
            })
            .catch((err) => {
              console.error('Error fetching current user:', err);
              setApiLoading(false);
              setIsUserEmailVerified(
                user.email_verified !== undefined ? user.email_verified : null
              );
            });
        })
        .catch((err) => {
          console.error('Error fetching current user:', err);
          setApiLoading(false);
          setIsUserEmailVerified(user.email_verified !== undefined ? user.email_verified : null);
        });
    } else if (!isLoading) {
      setApiLoading(false);
    }
  }, [user, isLoading, dispatch]);

  const retrieveAccessTokenSilently = async () => {
    return (
      getAccessTokenSilently()
        .then((data) => {
          localStorage.setItem('token', data);
          return data;
        })
        // eslint-disable-next-line @typescript-eslint/no-shadow
        .catch((error: any) => {
          console.log('what error === ', error);
          setApiLoading(false);
          return null;
        })
    );
  };

  return {
    isLoading: isLoading || apiLoading,
    isAuthenticated: isAuthenticated && isUserAuthenticated,
    isLoggedIn: !(isLoading || apiLoading) && user != null,
    isUserEmailVerified,
    user,
    error
  };
}
