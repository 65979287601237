/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { CombinedState, PayloadAction } from '@reduxjs/toolkit';
import { getAllServices } from 'lib/apis/service';
import type { OrganizationServicesQuery } from 'lib/graphql/__generated__/graphql';

import type { RootState } from '..';

interface ISelectedService {
  name: string | null;
  description: string | null;
  items: Array<{
    text?: string | null
  }>
}

interface IInitialState {
  services?: {
    data?: OrganizationServicesQuery['organizationServices'];
    loading: boolean;
    error?: string;
  };
  loading: boolean;
  error?: string;
  selectedService?: ISelectedService;
}

function createInitialState(): IInitialState {
  return {
    services: {
      data: undefined,
      loading: true,
      error: undefined
    },
    loading: true,
    error: undefined,
    selectedService: undefined
  };
}

const fetchAllServices = createAsyncThunk<
  OrganizationServicesQuery['organizationServices'],
  undefined,
  { state: RootState }
>('service/fetchAllServices', async (_, { rejectWithValue }) => {
  try {
    const services = await getAllServices();
    return services.organizationServices;
  } catch (err: unknown) {
    return rejectWithValue(err);
  }
});

const createExtraReducers = (builder: any) => {
  /**
   * GET ALL SERVICES
   */
  builder.addCase(
    fetchAllServices.fulfilled,
    (
      state: CombinedState<IInitialState>,
      action: PayloadAction<OrganizationServicesQuery['organizationServices']>
    ) => {
      state.services = {
        data: action.payload,
        loading: false,
        error: undefined
      };
      state.loading = false;
    }
  );
  builder.addCase(fetchAllServices.pending, (state: CombinedState<IInitialState>) => {
    state.services = {
      data: undefined,
      loading: true,
      error: undefined
    };
    state.loading = true;
  });
  builder.addCase(
    fetchAllServices.rejected,
    (
      state: CombinedState<IInitialState>,
      action: PayloadAction<unknown, string, unknown, Error>
    ) => {
      state.services = {
        data: undefined,
        loading: false,
        error: action.error.message
      };
      state.loading = false;
    }
  );
};

const slice = createSlice({
  name: 'service',
  initialState: createInitialState(),
  extraReducers: createExtraReducers,
  reducers: {
    setSelectedService(state, action: PayloadAction<ISelectedService>) {
      state.selectedService = action.payload;
    }
  }
});

// exports
export const serviceActions = { fetchAllServices };
export const { setSelectedService } = slice.actions;
export const serviceReducer = slice.reducer;
